<template>
    <div>
        <div class="row card-container">
            <div class="col winner-card" v-for="winner in winners" :key="winner.winnerId">
                <img class="winner-image" :alt="winner.winnerNumber" :src="`/images/${winner.imageFile}`" />
                <!-- <img class="winner-image" :alt="winner.winnerNumber"
                    :src="`https://source.unsplash.com/collection/928423/230x250`" /> -->
                <div class="winner-info">
                    <div class="winner-name">{{ winner.winnerNumber }}</div>
                    <div class="winner-details">
                        <p>{{ winner.winnerNumber }}</p>
                        <p>{{ winner.locationNumber }}</p>
                        <p>{{ winner.operatorNumber }}</p>
                        <a :href="winner.winnerNumber" target="_blank" class="winner-link">winner Webpage</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    components: {
    },
    props: {
        winners: Array,
    },

    methods: {

    },
};
</script>
  
<style scoped>
.card-container {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    margin: 5px;

}

.card {
    max-width: 230px;

}

.winner-picture-view {
    margin-bottom: 15px;
    /* Separate each row from the content below */
}

.winner-card {
    max-width: 250px;
    min-width: 250px;
    border-radius: 4px;
    padding: 0px;
    margin-bottom: 25px;
    /* Separate each card */
}

.winner-image {
    width: 250px;
    height: 200px;
    /* object-fit: contain; */
    border-radius: 4px;
}

.winner-info {
    margin-top: 0px;
}

.winner-name {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    line-height: 1.2;
}

.winner-details p {
    margin-top: 0px;
    text-align: left;
}

.winner-link {
    display: inline;
    margin-top: 5px;
    color: #1890ff;
}
</style>
  