import axios from "axios";

export default axios.create({
  baseURL: "https://drc-fsae.rocks:8080/",
//  baseURL: "http://localhost:8088/",
  headers: {
    "Content-type": "application/json",
  },
});


