<template>
  <div class="content">


  <h5>{{ this.teamId }}</h5>

  <div><input label="TeamId" type="text" placeholder="Enter TeamId" v-model="teamDetails.teamId"></input> TeamId<br><br></div>
  <div><input label="TeamName" type="text" placeholder="Enter TeamName" v-model="teamDetails.teamName"></input> TeamName<br><br></div>
  <div><input label="TeamSchool" type="text" placeholder="Enter TeamSchool" v-model="teamDetails.teamSchool"></input> TeamSchool<br><br></div>
  <div><input label="TeamLocation" type="text" placeholder="Enter TeamLocation" v-model="teamDetails.teamLocation"></input> TeamLocation<br><br></div>
  <div><input label="TeamUrl" type="text" placeholder="Enter TeamUrl" v-model="teamDetails.teamUrl"></input> TeamUrl<br><br></div>
  <div><input label="Created" type="text" placeholder="Enter Created" v-model="teamDetails.created"></input> Created<br><br></div>

  <div>
      <base-button class="btn btn-primary" @click="updateTeam()">Save</base-button>
  </div>

</div>
</template>
<script>

import TeamService from "../services/TeamService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";



export default {
  props: {
    teamId: {
      type: String,
      required: true
    }
  },
  components: {
  },
  data() {
    return {
      teamDetails: null
    };
  },
  methods: {
    async updateTeam() {

      const jsonData = JSON.stringify(this.teamDetails);
      const res = await TeamService.update(jsonData);
	


      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalTeams = false;
//        this.getAllTeams();
      }
    },

    async getTeamDetails() {
      try {
	    let response = await TeamService.get(this.teamId);
	    this.teamDetails = response.data;
      } catch (error) {
        console.error('Error fetching team details:', error);
      }
    }
  },
  mounted() {
    this.getTeamDetails();
  }
  
};
</script>
<style>
.input {
	text-align: center;
}
</style>


