<template>
  <div class="content">


  <h5>{{ this.competitionId }}</h5>

  <div><input label="CompetitionId" type="text" placeholder="Enter CompetitionId" v-model="competitionDetails.competitionId"></input> CompetitionId<br><br></div>
  <div><input label="CompetitionAbbrev" type="text" placeholder="Enter CompetitionAbbrev" v-model="competitionDetails.competitionAbbrev"></input> CompetitionAbbrev<br><br></div>
  <div><input label="CompetitionName" type="text" placeholder="Enter CompetitionName" v-model="competitionDetails.competitionName"></input> CompetitionName<br><br></div>
  <div><input label="CompetitionSite" type="text" placeholder="Enter CompetitionSite" v-model="competitionDetails.competitionSite"></input> CompetitionSite<br><br></div>
  <div><input label="Created" type="text" placeholder="Enter Created" v-model="competitionDetails.created"></input> Created<br><br></div>

  <div>
      <base-button class="btn btn-primary" @click="updateCompetition()">Save</base-button>
  </div>

</div>
</template>
<script>

import CompetitionService from "../services/CompetitionService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";



export default {
  props: {
    competitionId: {
      type: String,
      required: true
    }
  },
  components: {
  },
  data() {
    return {
      competitionDetails: null
    };
  },
  methods: {
    async updateCompetition() {

      const jsonData = JSON.stringify(this.competitionDetails);
      const res = await CompetitionService.update(jsonData);
	


      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalCompetitions = false;
//        this.getAllCompetitions();
      }
    },

    async getCompetitionDetails() {
      try {
	    let response = await CompetitionService.get(this.competitionId);
	    this.competitionDetails = response.data;
      } catch (error) {
        console.error('Error fetching competition details:', error);
      }
    }
  },
  mounted() {
    this.getCompetitionDetails();
  }
  
};
</script>
<style>
.input {
	text-align: center;
}
</style>


