<template>
  <div class="content">
    <!-- search bar -->
    <div class="row my-3">
      <div class="col-8"></div>
      <div class="col-4">
        <!-- Header Search Input -->
        <a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Search by BusinessUnit#, Location#, Operator#, City, State, FirstName, LastName…"
          @search="onSearch" :loading='searchLoading' v-model="searchQuery">
          <svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
              fill="#111827" />
          </svg>
        </a-input-search>
        <!-- / Header Search Input -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row justify-content-between align-items-between mx-3">

              <h4 class="card-title">Teams</h4>
              
              <div>
                  <base-button class="btn btn-primary" @click="modalTeams = true">Add</base-button>
              </div>
              
              <modal :show.sync="modalTeams">
                <template slot="header">
                  <h5 class="modal-title" id="exampleModalLabel">Add Team</h5>
                </template>
                <div>
                  <form @submit.prevent>
  <base-input label="TeamName" type="text" placeholder="Enter TeamName" v-model="teamsData.teamName"></base-input>
  <base-input label="TeamSchool" type="text" placeholder="Enter TeamSchool" v-model="teamsData.teamSchool"></base-input>
  <base-input label="TeamLocation" type="text" placeholder="Enter TeamLocation" v-model="teamsData.teamLocation"></base-input>
  <base-input label="TeamUrl" type="text" placeholder="Enter TeamUrl" v-model="teamsData.teamUrl"></base-input>
                  </form>
                </div>
                <template slot="footer">
                  <base-button type="primary" @click="submitTeams()">Save</base-button>
                </template>
              </modal>
            </div>
          </template>


          <!-- Conditionally render the view based on the 'isTableView' flag -->
          <div v-if="isTableView">
            <!-- Render the existing Table View -->
            <a-table :columns="columns" :data-source="teams" :row-key="record => record.TeamId" :pagination="pagination"
              :loading="searchLoading" @change="onTableChange" :scroll="{ x: 'max-content' }">
            </a-table>
          </div>
          <div v-else>
            <!-- Render the Picture View  -->
            <TeamPictureView :teams="teams" />
          </div>

        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import TeamService from "../services/TeamService";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import TeamPictureView from './TeamPictureView.vue';


const teamsColumns = [
  "teamId",
  "year",
  "date",
  "competitionId",
  "teamId"
]

export default {
  components: {
    Card,
    Modal,
    BaseButton,
    BaseInput,
    Table,

    Pagination,
    TeamPictureView
  },

  data() {
    return {
      modalTeams: false,
        isTableView: true,

      columns: [
        {
          title: 'TeamId',
          dataIndex: 'teamId',
          visible: true,
          //scopedSlots: { customRender: 'teamId' },
          //sorter: (a, b) => a.teamId - b.teamId,
          //sorter: (a, b) => a.teamId.localeCompare(b.teamId),
        },
        {
          title: 'TeamName',
          dataIndex: 'teamName',
          visible: true,
          //scopedSlots: { customRender: 'teamName' },
          //sorter: (a, b) => a.teamName - b.teamName,
          //sorter: (a, b) => a.teamName.localeCompare(b.teamName),
        },
        {
          title: 'TeamSchool',
          dataIndex: 'teamSchool',
          visible: true,
          //scopedSlots: { customRender: 'teamSchool' },
          //sorter: (a, b) => a.teamSchool - b.teamSchool,
          //sorter: (a, b) => a.teamSchool.localeCompare(b.teamSchool),
        },
        {
          title: 'TeamLocation',
          dataIndex: 'teamLocation',
          visible: true,
          //scopedSlots: { customRender: 'teamLocation' },
          //sorter: (a, b) => a.teamLocation - b.teamLocation,
          //sorter: (a, b) => a.teamLocation.localeCompare(b.teamLocation),
        },
        {
          title: 'TeamUrl',
          dataIndex: 'teamUrl',
          visible: true,
          //scopedSlots: { customRender: 'teamUrl' },
          //sorter: (a, b) => a.teamUrl - b.teamUrl,
          //sorter: (a, b) => a.teamUrl.localeCompare(b.teamUrl),
        },
        {
          title: 'Created',
          dataIndex: 'created',
          visible: true,
          //scopedSlots: { customRender: 'created' },
          //sorter: (a, b) => a.created - b.created,
          //sorter: (a, b) => a.created.localeCompare(b.created),
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `Total ${total} teams`,
      },

      teamsData: {
        teamId: null,
        year: '',
        date: '',
        competitionId: '',
        teamId: ''
      },

      teams: [],


      teamsTable: {
        columns: [...teamsColumns],
        data: [],
      },

      // New properties for sorting and searching
      sortBy: 'teamId',           // Column to sort by
      sortOrder: 'asc',     // Sort order (asc or desc)
      searchQuery: '',      // Search query
      searchLoading: false, // Initialize searchLoading property


    };
  },
  watch: {
    searchQuery: {
      handler: "getAllTeams", // Call the fetchData method when searchQuery changes
      immediate: true, // Trigger immediately when the component is mounted
    },
  },

  methods: {

    async submitTeams() {
      const currentDate = new Date().getTime();
      this.teamsData.created = currentDate;

      const jsonData = JSON.stringify(this.teamsData);
      const res = await TeamService.addTeam(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });

        this.modalTeams = false;
        this.getAllTeams();
      }
    },

    async getAllTeams() {
      this.searchLoading = true; // Set searchLoading to true while fetching data

      const response = await TeamService.getAllTeams(this.sortBy, this.sortOrder, this.searchQuery, this.pagination.current,
        this.pagination.pageSize
      );
      this.teams = response.data.content;
      this.pagination.total = response.data.totalElements;
      this.searchLoading = false;

      console.log(this.teams);
      let teamsTableData = [];
      for (let i = 0; i < this.teams.length; i++) {
        teamsTableData.push({
          id: i,
          teamId: this.teams[i].teamId,
          year: this.teams[i].year,
          date: this.teams[i].date,
          competitionId: this.teams[i].competitionId,
          teamId: this.teams[i].teamId,
        });

      }
      this.searchLoading = false;


    },
    async onTableChange(pagination, filters, sorter) {
      if (sorter && sorter.field && sorter.order) {
        this.sortBy = sorter.field;
        if (sorter.order == "ascend") {
            this.sortOrder = "asc";
        } else {
            this.sortOrder = "desc";
        }
      }
      if (pagination) {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
      }

      await this.getAllTeams();
    },

    onSearch(value) {
      console.log(value);
      this.searchQuery = value; // Update searchQuery when the user types
    },

    toggleView() {
      this.isTableView = !this.isTableView;
    },
  },

  mounted() {
    this.getAllTeams();
  }
};
</script>

<style>
.modal-dialog {
  margin-top: -300px;
}
</style>
