<template>
    <div>
        <div class="row card-container">
            <div class="col team-card" v-for="team in teams" :key="team.teamId">
                <img class="team-image" :alt="team.teamNumber" :src="`/images/${team.imageFile}`" />
                <!-- <img class="team-image" :alt="team.teamNumber"
                    :src="`https://source.unsplash.com/collection/928423/230x250`" /> -->
                <div class="team-info">
                    <div class="team-name">{{ team.teamNumber }}</div>
                    <div class="team-details">
                        <p>{{ team.teamNumber }}</p>
                        <p>{{ team.locationNumber }}</p>
                        <p>{{ team.operatorNumber }}</p>
                        <a :href="team.teamNumber" target="_blank" class="team-link">team Webpage</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    components: {
    },
    props: {
        teams: Array,
    },

    methods: {

    },
};
</script>
  
<style scoped>
.card-container {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    margin: 5px;

}

.card {
    max-width: 230px;

}

.team-picture-view {
    margin-bottom: 15px;
    /* Separate each row from the content below */
}

.team-card {
    max-width: 250px;
    min-width: 250px;
    border-radius: 4px;
    padding: 0px;
    margin-bottom: 25px;
    /* Separate each card */
}

.team-image {
    width: 250px;
    height: 200px;
    /* object-fit: contain; */
    border-radius: 4px;
}

.team-info {
    margin-top: 0px;
}

.team-name {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    line-height: 1.2;
}

.team-details p {
    margin-top: 0px;
    text-align: left;
}

.team-link {
    display: inline;
    margin-top: 5px;
    color: #1890ff;
}
</style>
  