import http from "../http-common";

class CompetitionService {
  getAllCompetitions(sortBy = "", sortOrder = "", searchQuery = "", currentPage = "", pageSize = "") {
    console.log(sortBy, sortOrder, searchQuery, currentPage, pageSize)
    return http.get(`/competition/competitions?page=${currentPage-1}&size=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchQuery=${searchQuery}`);
  }

  get(competitionId) {
  	return http.get(`/competition/${competitionId}`);
  }

  findByField(matchData) {
  	return http.get(`/competition?field=${matchData}`);
  }

  addCompetition(data) {
    return http.post("/competition/addCompetition", data);
  }

  update(data) {
  	return http.post("/competition/updateCompetition", data);
  }
}

export default new CompetitionService();
