import http from "../http-common";

class WinnerService {
  getAllWinners(sortBy = "", sortOrder = "", searchQuery = "", currentPage = "", pageSize = "") {
    console.log(sortBy, sortOrder, searchQuery, currentPage, pageSize)
    return http.get(`/winner/winners?page=${currentPage-1}&size=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchQuery=${searchQuery}`);
  }

  get(winnerId) {
  	return http.get(`/winner/${winnerId}`);
  }

  findByField(matchData) {
  	return http.get(`/winner?field=${matchData}`);
  }

  addWinner(data) {
    return http.post("/winner/addWinner", data);
  }

  update(data) {
  	return http.post("/winner/updateWinner", data);
  }
}

export default new WinnerService();
