<template>
  <div class="content">
    <!-- search bar -->
    <div class="row my-3">
      <div class="col-8"></div>
      <div class="col-4">
        <!-- Header Search Input -->
        <a-input-search class="header-search" :class="searchLoading ? 'loading' : ''" placeholder="Search by BusinessUnit#, Location#, Operator#, City, State, FirstName, LastName…"
          @search="onSearch" :loading='searchLoading' v-model="searchQuery">
          <svg slot="prefix" width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
              fill="#111827" />
          </svg>
        </a-input-search>
        <!-- / Header Search Input -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row justify-content-between align-items-between mx-3">

              <h4 class="card-title">Winners</h4>
              
              <div>
                  <base-button class="btn btn-primary" @click="modalWinners = true">Add</base-button>
              </div>
              
              <modal :show.sync="modalWinners">
                <template slot="header">
                  <h5 class="modal-title" id="exampleModalLabel">Add Winner</h5>
                </template>
                <div>
                  <form @submit.prevent>
  <base-input label="Year" type="text" placeholder="Enter Year" v-model="winnersData.year"></base-input>
  <base-input label="Date" type="text" placeholder="Enter Date" v-model="winnersData.date"></base-input>
  <base-input label="TeamId" type="text" placeholder="Enter TeamId" v-model="winnersData.teamId"></base-input>
  <base-input label="ImageFile" type="text" placeholder="Enter ImageFile" v-model="winnersData.imageFile"></base-input>
  <base-input label="ResultsFile" type="text" placeholder="Enter ResultsFile" v-model="winnersData.resultsFile"></base-input>
  <base-input label="Notes" type="text" placeholder="Enter Notes" v-model="winnersData.notes"></base-input>
  <base-input label="Published" type="text" placeholder="Enter Published" v-model="winnersData.published"></base-input>
                  </form>
                </div>
                <template slot="footer">
                  <base-button type="primary" @click="submitWinners()">Save</base-button>
                </template>
              </modal>
            </div>
          </template>


          <!-- Conditionally render the view based on the 'isTableView' flag -->
          <div v-if="isTableView">
            <!-- Render the existing Table View -->
            <a-table :columns="columns" :data-source="winners" :row-key="record => record.WinnerId" :pagination="pagination"
              :loading="searchLoading" @change="onTableChange" :scroll="{ x: 'max-content' }">
            </a-table>
          </div>
          <div v-else>
            <!-- Render the Picture View  -->
            <WinnerPictureView :winners="winners" />
          </div>

        </card>
      </div>
    </div>

  </div>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import WinnerService from "../services/WinnerService";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import WinnerPictureView from './WinnerPictureView.vue';


const winnersColumns = [
  "winnerId",
  "year",
  "date",
  "competitionId",
  "winnerId"
]

export default {
  components: {
    Card,
    Modal,
    BaseButton,
    BaseInput,
    Table,

    Pagination,
    WinnerPictureView
  },

  data() {
    return {
      modalWinners: false,
        isTableView: true,

      columns: [
        {
          title: 'WinnerId',
          dataIndex: 'winnerId',
          visible: true,
          //scopedSlots: { customRender: 'winnerId' },
          //sorter: (a, b) => a.winnerId - b.winnerId,
          //sorter: (a, b) => a.winnerId.localeCompare(b.winnerId),
        },
        {
          title: 'Year',
          dataIndex: 'year',
          visible: true,
          //scopedSlots: { customRender: 'year' },
          //sorter: (a, b) => a.year - b.year,
          //sorter: (a, b) => a.year.localeCompare(b.year),
        },
        {
          title: 'Date',
          dataIndex: 'date',
          visible: true,
          //scopedSlots: { customRender: 'date' },
          //sorter: (a, b) => a.date - b.date,
          //sorter: (a, b) => a.date.localeCompare(b.date),
        },
        {
          title: 'TeamId',
          dataIndex: 'teamId',
          visible: true,
          //scopedSlots: { customRender: 'teamId' },
          //sorter: (a, b) => a.teamId - b.teamId,
          //sorter: (a, b) => a.teamId.localeCompare(b.teamId),
        },
        {
          title: 'CompetitionId',
          dataIndex: 'competitionId',
          visible: true,
          //scopedSlots: { customRender: 'competitionId' },
          //sorter: (a, b) => a.competitionId - b.competitionId,
          //sorter: (a, b) => a.competitionId.localeCompare(b.competitionId),
        },
        {
          title: 'CarNumber',
          dataIndex: 'carNumber',
          visible: true,
          //scopedSlots: { customRender: 'carNumber' },
          //sorter: (a, b) => a.carNumber - b.carNumber,
          //sorter: (a, b) => a.carNumber.localeCompare(b.carNumber),
        },
        {
          title: 'ImageFile',
          dataIndex: 'imageFile',
          visible: true,
          //scopedSlots: { customRender: 'imageFile' },
          //sorter: (a, b) => a.imageFile - b.imageFile,
          //sorter: (a, b) => a.imageFile.localeCompare(b.imageFile),
        },
        {
          title: 'ResultsFile',
          dataIndex: 'resultsFile',
          visible: true,
          //scopedSlots: { customRender: 'resultsFile' },
          //sorter: (a, b) => a.resultsFile - b.resultsFile,
          //sorter: (a, b) => a.resultsFile.localeCompare(b.resultsFile),
        },
        {
          title: 'Notes',
          dataIndex: 'notes',
          visible: true,
          //scopedSlots: { customRender: 'notes' },
          //sorter: (a, b) => a.notes - b.notes,
          //sorter: (a, b) => a.notes.localeCompare(b.notes),
        },
        {
          title: 'Published',
          dataIndex: 'published',
          visible: true,
          //scopedSlots: { customRender: 'published' },
          //sorter: (a, b) => a.published - b.published,
          //sorter: (a, b) => a.published.localeCompare(b.published),
        },
        {
          title: 'Created',
          dataIndex: 'created',
          visible: true,
          //scopedSlots: { customRender: 'created' },
          //sorter: (a, b) => a.created - b.created,
          //sorter: (a, b) => a.created.localeCompare(b.created),
        },
      ],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `Total ${total} winners`,
      },

      winnersData: {
        winnerId: null,
        year: '',
        date: '',
        competitionId: '',
        winnerId: ''
      },

      winners: [],


      winnersTable: {
        columns: [...winnersColumns],
        data: [],
      },

      // New properties for sorting and searching
      sortBy: 'winnerId',           // Column to sort by
      sortOrder: 'asc',     // Sort order (asc or desc)
      searchQuery: '',      // Search query
      searchLoading: false, // Initialize searchLoading property


    };
  },
  watch: {
    searchQuery: {
      handler: "getAllWinners", // Call the fetchData method when searchQuery changes
      immediate: true, // Trigger immediately when the component is mounted
    },
  },

  methods: {

    async submitWinners() {
      const currentDate = new Date().getTime();
      this.winnersData.created = currentDate;

      const jsonData = JSON.stringify(this.winnersData);
      const res = await WinnerService.addWinner(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });

        this.modalWinners = false;
        this.getAllWinners();
      }
    },

    async getAllWinners() {
      this.searchLoading = true; // Set searchLoading to true while fetching data

      const response = await WinnerService.getAllWinners(this.sortBy, this.sortOrder, this.searchQuery, this.pagination.current,
        this.pagination.pageSize
      );
      this.winners = response.data.content;
      this.pagination.total = response.data.totalElements;
      this.searchLoading = false;

      console.log(this.winners);
      let winnersTableData = [];
      for (let i = 0; i < this.winners.length; i++) {
        winnersTableData.push({
          id: i,
          winnerId: this.winners[i].winnerId,
          year: this.winners[i].year,
          date: this.winners[i].date,
          competitionId: this.winners[i].competitionId,
          winnerId: this.winners[i].winnerId,
        });

      }
      this.searchLoading = false;


    },
    async onTableChange(pagination, filters, sorter) {
      if (sorter && sorter.field && sorter.order) {
        this.sortBy = sorter.field;
        if (sorter.order == "ascend") {
            this.sortOrder = "asc";
        } else {
            this.sortOrder = "desc";
        }
      }
      if (pagination) {
        this.pagination.current = pagination.current;
        this.pagination.pageSize = pagination.pageSize;
      }

      await this.getAllWinners();
    },

    onSearch(value) {
      console.log(value);
      this.searchQuery = value; // Update searchQuery when the user types
    },

    toggleView() {
      this.isTableView = !this.isTableView;
    },
  },

  mounted() {
    this.getAllWinners();
  }
};
</script>

<style>
.modal-dialog {
  margin-top: -300px;
}
</style>
