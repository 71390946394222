<template>
    <div>
        <div class="row card-container">
            <div class="col competition-card" v-for="competition in competitions" :key="competition.competitionId">
                <img class="competition-image" :alt="competition.competitionNumber" :src="`/images/${competition.imageFile}`" />
                <!-- <img class="competition-image" :alt="competition.competitionNumber"
                    :src="`https://source.unsplash.com/collection/928423/230x250`" /> -->
                <div class="competition-info">
                    <div class="competition-name">{{ competition.competitionNumber }}</div>
                    <div class="competition-details">
                        <p>{{ competition.competitionNumber }}</p>
                        <p>{{ competition.locationNumber }}</p>
                        <p>{{ competition.operatorNumber }}</p>
                        <a :href="competition.competitionNumber" target="_blank" class="competition-link">competition Webpage</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    components: {
    },
    props: {
        competitions: Array,
    },

    methods: {

    },
};
</script>
  
<style scoped>
.card-container {
    display: flex;
    column-gap: 15px;
    flex-wrap: wrap;
    margin: 5px;

}

.card {
    max-width: 230px;

}

.competition-picture-view {
    margin-bottom: 15px;
    /* Separate each row from the content below */
}

.competition-card {
    max-width: 250px;
    min-width: 250px;
    border-radius: 4px;
    padding: 0px;
    margin-bottom: 25px;
    /* Separate each card */
}

.competition-image {
    width: 250px;
    height: 200px;
    /* object-fit: contain; */
    border-radius: 4px;
}

.competition-info {
    margin-top: 0px;
}

.competition-name {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    line-height: 1.2;
}

.competition-details p {
    margin-top: 0px;
    text-align: left;
}

.competition-link {
    display: inline;
    margin-top: 5px;
    color: #1890ff;
}
</style>
  