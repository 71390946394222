<template>
  <div class="content">


  <h5>{{ this.winnerId }}</h5>

  <div><input label="WinnerId" type="text" placeholder="Enter WinnerId" v-model="winnerDetails.winnerId"></input><br>WinnerId<br><br></div>
  <div><input label="Year" type="text" placeholder="Enter Year" v-model="winnerDetails.year"></input><br>Year<br><br></div>
  <div><input label="Date" type="text" placeholder="Enter Date" v-model="winnerDetails.date"></input><br>Date<br><br></div>
  <div><input label="TeamId" type="text" placeholder="Enter TeamId" v-model="winnerDetails.teamId"></input><br>TeamId<br><br></div>
  <div  style="display: inline-block;">
  <vue-autosuggest label="CompetitionId" type="text" placeholder="Enter CompetitionId" v-model="winnerDetails.competitionId" :suggestions="filteredSuggestions" :input-props="{id:'autosuggest__input'}" @selected="selectHandler" @input="onInputChange" :getSuggestionValue="s => s.item.competitionId.toString()">
  
    <template slot-scope="{ suggestion }">
      <div>
        <b>{{suggestion.item.competitionName}}</b>
      </div>
    </template>
  </vue-autosuggest>CompetitionId
  <br><br>
</div>

  <div><input label="CarNumber" type="text" placeholder="Enter CarNumber" v-model="winnerDetails.carNumber"></input><br>CarNumber<br><br></div>
  <div><input label="ImageFile" type="text" placeholder="Enter ImageFile" v-model="winnerDetails.imageFile"></input><br>ImageFile<br><br></div>
  <div><input label="ResultsFile" type="text" placeholder="Enter ResultsFile" v-model="winnerDetails.resultsFile"></input><br>ResultsFile<br><br></div>
  <div><input label="Notes" type="text" placeholder="Enter Notes" v-model="winnerDetails.notes"></input><br>Notes<br><br></div>
  <div><input label="Published" type="text" placeholder="Enter Published" v-model="winnerDetails.published"></input><br>Published<br><br></div>
  <div><input label="Created" type="text" placeholder="Enter Created" v-model="winnerDetails.created"></input><br>Created<br><br></div>

  <div>
      <base-button class="btn btn-primary" @click="updateWinner()">Save</base-button>
  </div>

</div>
</template>
<script>

import WinnerService from "../services/WinnerService";
import CompetitionService from "../services/CompetitionService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import { ASelect, ASelectOption, AButton, Table, Pagination } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";  


export default {
  props: {
    winnerId: {
      type: String,
      required: true
    }
  },
  components: {
      VueAutosuggest
  },
  data() {
    return {
      competitionNameQuery: "",
      winnerDetails: null,
      suggestions: [],
      suggestionsLoaded: false
    };
  },
  methods: {

    selectHandler(item)
    {
      this.winnerDetails.competitionId = item.item.competitionId.toString();
    },

    async getCompetitionNameSuggestions()
    {
      try {
        let response = await CompetitionService.getAllCompetitions("competitionId","asc",this.winnerDetails.competitionId,"1","15");
        this.suggestions = response.data.content;
        this.suggestionsLoaded = true;
      } catch (error) {
        console.error('Error fetching competitions list for auto-complete: ', error);
      }

    },

    onInputChange(text) {
      this.getCompetitionNameSuggestions();
    },

    async updateWinner() {
      const jsonData = JSON.stringify(this.winnerDetails);
      const res = await WinnerService.update(jsonData);
    


      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalWinners = false;
//        this.getAllWinners();
      }
    },

    async getWinnerDetails() {
      try {
        let response = await WinnerService.get(this.winnerId);
        this.winnerDetails = response.data;
      } catch (error) {
        console.error('Error fetching winner details:', error);
      }
    }
  },
  mounted() {
    this.getWinnerDetails();
  },

  computed: {
  filteredSuggestions() {
    return [
      {
        data: this.suggestions
      }
    ];
  }
},
  
};
</script>
<style>
.input {
    text-align: center;
}

#autosuggest__input {
  display: inline-block;
}

.autosuggest__results-container {
position: relative;
}

.autosuggest__results {
font-weight: 300;
margin: 0;
position: absolute;
z-index: 10000001;
border: 1px solid #e0e0e0;
border-bottom-left-radius: 4px;
border-bottom-right-radius: 4px;
background: white;
}

.autosuggest__results ul {
list-style: none;
padding-left: 0;
margin: 0;
}

.autosuggest__results .autosuggest__results-item {
cursor: pointer;
padding: 5px;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
border-top: none;
}

.autosuggest__results .autosuggest__results_title {
color: gray;
font-size: 11px;
margin-left: 0;
padding: 15px 13px 5px;
border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
background-color: #F6F6F6;
}
</style>