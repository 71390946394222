import http from "../http-common";

class TeamService {
  getAllTeams(sortBy = "", sortOrder = "", searchQuery = "", currentPage = "", pageSize = "") {
    console.log(sortBy, sortOrder, searchQuery, currentPage, pageSize)
    return http.get(`/team/teams?page=${currentPage-1}&size=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&searchQuery=${searchQuery}`);
  }

  get(teamId) {
  	return http.get(`/team/${teamId}`);
  }

  findByField(matchData) {
  	return http.get(`/team?field=${matchData}`);
  }

  addTeam(data) {
    return http.post("/team/addTeam", data);
  }

  update(data) {
  	return http.post("/team/updateTeam", data);
  }
}

export default new TeamService();
